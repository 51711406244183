<template>
  <div class="app-layout">
    <div class="appContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import '@/assets/css/shared/appLayout.css'

export default {
  name: 'appLayout'
};
</script>