import { createRouter, createWebHistory } from 'vue-router';
import landingPage from '@/components/landingPage.vue';
import privacyPolicy from '@/components/privacyPolicy.vue';
import avisoLegal from '@/components/avisoLegal.vue';
import cookiePolicy from '@/components/cookiePolicy.vue';

const routes = [
  {
    path: '/',
    name: 'landingPage',
    component: landingPage
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: privacyPolicy
  },
  {
    path: '/aviso-legal',
    name: 'avisoLegal',
    component: avisoLegal
  },
  {
    path: '/cookie-policy',
    name: 'cookiePolicy',
    component: cookiePolicy
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;