<template>
    <appLayout>
      <section class="logo-and-brand-cookie" id="logo-and-brand">
        <div class="logo-and-brand-cookie-first">
          <div class="logo-and-brand-cookie-first-start">
            <div class="logo-and-brand-cookie-first-start-first">
              <img @click="backToMain" class="analizitis-logo" src="@/assets/images/analizitis.png" alt="Analizitis logo"> 
              <span @click="backToMain" class="brand">Analizitis</span>
            </div>
          </div>
        </div>
      </section>
      <div class="content-cookie">
        <div class="content-cookie-start">
          <div class="content-cookie-start-first">
            <h1>Politica de cookies</h1>
          </div>
          <div class="content-cookie-start-second">
            <p>Fecha de Vigencia: desde julio de 2024</p>
            <p class="cookie-title">1. Introducción</p>
            <p>En Analizitis ("la Aplicación") utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario, proporcionar servicios y analizar el tráfico. Esta Política de Cookies explica qué son las cookies, cómo las utilizamos y cómo puedes gestionarlas. Es importante señalar que no recopilamos ni almacenamos información personal identificable de los usuarios a través de las cookies.</p>
            
            <p class="cookie-title">2. ¿Qué son las Cookies?</p>
            <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo (ordenador, tableta, smartphone) cuando visitas una página web o utilizas una aplicación. Las cookies permiten a la web o aplicación recordar tus acciones y preferencias (como idioma, tamaño de fuente y otras preferencias de visualización) durante un periodo de tiempo.</p>
            
            <p class="cookie-title">3. Tipos de Cookies que Utilizamos</p>
            <p>Cookies Necesarias: Estas cookies son esenciales para el funcionamiento de la Aplicación y te permiten navegar y utilizar sus características. Sin estas cookies, algunos servicios no pueden prestarse</p>
            <p>Cookies de Rendimiento: Recopilan información sobre cómo los usuarios interactúan con la Aplicación, como las páginas que visitan con más frecuencia y si reciben mensajes de error de las páginas web. Estas cookies no recopilan información que identifique a un visitante. Toda la información recopilada por estas cookies es anónima y se utiliza solo para mejorar el funcionamiento de la Aplicación.</p>

            <p class="cookie-title">4. Cómo Gestionar las Cookies</p>
            <p>Puedes controlar y/o eliminar las cookies como desees. Para más detalles, consulta aboutcookies.org. Puedes eliminar todas las cookies que ya están en tu ordenador y configurar la mayoría de los navegadores para que no se acepten. Pero si haces esto, es posible que tengas que ajustar manualmente algunas preferencias cada vez que visites un sitio y que algunos servicios y funcionalidades no funcionen.</p>
            
            <p class="cookie-title">5. Cambios en la Política de Cookies</p>
            <p>Nos reservamos el derecho de modificar esta Política de Cookies en cualquier momento. Cualquier cambio en esta política se publicará en esta página y, si es necesario, se notificará a los usuarios. Te recomendamos que revises esta página periódicamente para estar al tanto de cualquier cambio.</p>
            
            <p class="cookie-title">6. Contacto</p>
            <p>Para cualquier consulta o comentario acerca del presente Aviso cookie, puede ponerse en contacto con nosotros a través del siguiente correo electrónico: analizitisai@gmail.com</p>
          </div>
        </div>
      </div>
    </appLayout>
</template>
  
  <script>
  import appLayout from '@/components/shared/appLayout.vue';
  import '@/assets/css/cookiePolicy.css';


  export default {
    name: 'cookiePolicy',
    components: {
        appLayout
    },
    methods: {
      backToMain() {
        this.$router.push('/');
      }
    }
  };
  </script>