<template>
  <appLayout>
    <header class="header" :style="backgroundImage">
      <img class="logo" src="@/assets/images/analizitis.png" alt="Analizitis Logo">
      <h1 class="header-title">{{languageChosen=='es'?'Bienvenido a Analizitis':'Welcome to Analizitis'}}</h1>
      <h2 style="color: #a81cd0;font-size: 33px;padding-bottom: 15px;">{{languageChosen=='es'?'Acompaña a LaIA a descubrir una nueva era de tecnología de vanguardia':
        'Join LaIA in discovering a new era of cutting-edge technology'}}</h2>
      <p id="texto-escrito" style="font-family: monospace; font-size: 22px;"></p>

      <a class="scroll-button" href="#logo-and-brand">{{languageChosen=='es'?'¡Empezar Ahora!':'Start Now!'}}</a>
      <cookieBanner></cookieBanner>
    </header>
    <section class="logo-and-brand" id="logo-and-brand">
      <div class="logo-and-brand-first">
        <div class="logo-and-brand-first-start">
          <div class="logo-and-brand-first-start-first">
            <img class="analizitis-logo" src="@/assets/images/analizitis.png" alt="Analizitis logo"> 
            <span class="brand">Analizitis</span>
          </div>
          <div class="logo-and-brand-first-start-second">
            <div class="toggleLanguage">
              <p class="toggleLanguage-p es" @click="clickCheckboxLanguage">ESP</p>
              <label class="switch">
                <input type="checkbox" ref="languageCheckbox" id="toggleButton" @change="changeLanguage">
                <span class="slider"></span>
              </label>
              <p class="toggleLanguage-p en" @click="clickCheckboxLanguage">ENG</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div>
      <section class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Primero elige categoria':'First, choose category'}}</h2>
        <button v-if="startIndexCategory > 0" @click="prevClickCategory" class="prev-button">&#10094;</button>
        <div v-if="categories.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexCategory * 115}%)` }" @click="getSelectedCategory" :id="category._id" class="carousel-item" v-for="(category, index) in categories" :key="index">
            <img class="border-image" :src="getImagePath('category', category.image)" :alt=category.name>
            <p>{{category.name}}</p>
          </div>
        </div>
        <button v-if="startIndexCategory < categories.length" @click="nextClickCategory" class="next-button">&#10095;</button>
      </section>

      <section v-if="isPc" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige característica principal':'Then, choose main feature'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="pcs.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="pcfeature._id" v-for="(pcfeature, index) in pcs" :key="index">
            <img class="border-image" :src="getImagePath('pc', pcfeature.image)" :alt=pcfeature.name>
            <p>{{pcfeature.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isLaptop" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige característica principal':'Then, choose main feature'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="laptops.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="laptopFeature._id" v-for="(laptopFeature, index) in laptops" :key="index">
            <img class="border-image" :src="getImagePath('laptop', laptopFeature.image)" :alt=laptopFeature.name>
            <p>{{laptopFeature.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isTablet" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige característica principal':'Then, choose main feature'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="tablets.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="tabletFeature._id" v-for="(tabletFeature, index) in tablets" :key="index">
            <img class="border-image" :src="getImagePath('tablet', tabletFeature.image)" :alt=tabletFeature.name>
            <p>{{tabletFeature.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isSmartwatch" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige característica principal':'Then, choose main feature'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="smartwatches.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="smartwatchFeature._id" v-for="(smartwatchFeature, index) in smartwatches" :key="index">
            <img class="border-image" :src="getImagePath('smartwatch', smartwatchFeature.image)" :alt=smartwatchFeature.name>
            <p>{{smartwatchFeature.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isSmartphone" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige característica principal':'Then, choose main feature'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="smartphones.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="smartphone._id" v-for="(smartphone, index) in smartphones" :key="index">
            <img class="border-image" :src="getImagePath('smartphone', smartphone.image)" :alt=smartphone.name>
            <p>{{smartphone.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isMonitor" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige característica principal':'Then, choose main feature'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="monitors.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="monitor._id" v-for="(monitor, index) in monitors" :key="index">
            <img class="border-image" :src="getImagePath('monitor', monitor.image)" :alt=monitor.name>
            <p>{{monitor.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isTv" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige hobby':'Then, choose hobby'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="hobbies.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="hobby._id" v-for="(hobby, index) in hobbies" :key="index">
            <img class="border-image" :src="getImagePath('hobby', hobby.image)" :alt=hobby.name>
            <p>{{hobby.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isCar" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige tipo':'Then, choose type'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="carModels.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="carModel._id" v-for="(carModel, index) in carModels" :key="index">
            <img class="border-image" :src="getImagePath('carType', carModel.image)" :alt=carModel.name>
            <p>{{carModel.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>
      <section v-if="isMoto" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Después, elige tipo':'Then, choose type'}}</h2>
        <button v-if="startIndexSecond > 0" @click="prevClickSecond" class="prev-button">&#10094;</button>
        <div v-if="motorbikeModels.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexSecond * 115}%)` }" class="carousel-item" @click="getSelectedSecondRow" :id="motorbikeModel._id" v-for="(motorbikeModel, index) in motorbikeModels" :key="index">
            <img class="border-image" :src="getImagePath('bikeType', motorbikeModel.image)" :alt=motorbikeModel.name>
            <p>{{motorbikeModel.name}}</p>
          </div>
        </div>
        <button @click="nextClickSecond" class="next-button">&#10095;</button>
      </section>

      <section v-if="selectedSecondElement" @click="envioPregunta" class="carousel-wrapper">
        <h2 class="carousel-title">{{languageChosen=='es'?'Por último, elige gama':'Finally, choose budget'}}</h2>
        <button v-if="startIndexThird > 0" @click="prevClickThird" class="prev-button">&#10094;</button>
        <div v-if="budgets.length > 0" class="carousel">
          <div :style="{ transform: `translateX(-${startIndexThird * 115}%)` }" class="carousel-item" @click="getSelectedThirdRow" :id="budget._id" v-for="(budget, index) in budgets" :key="index">
            <img class="border-image" :src="getImagePath('budget', budget.image)" :alt=budget.name>
            <p>{{budget.name}}</p>
          </div>
        </div>
        <button @click="nextClickThird" class="next-button">&#10095;</button>
      </section>
    </div>
    
    <div v-if="loading && !formattedIAReply">
      <mainSpinner />
    </div>
    <div class="iareply-container">
      <div class="iareply-container-first">
        <div v-if="!loading && formattedIAReply" class="iareply-container-message">
          <div class="iareply-container-message-summary">
            <div class="iareply-container-message-summary-start">
              <span>{{languageChosen=='es'?"Las caracarterísticas óptimas para tu "+this.categoryName+" son:":"The optimal specifications for your "+this.categoryName+" are:" }}</span>
            </div>
          </div>

          <div class="iareply-container-message-first">
            <div class="iareply-container-message-first-start">
              <div v-html="formattedIAReply"></div>
            </div>
          </div>
          <div class="iareply-container-disclaimer">
            <div class="iareply-container-disclaimer-first">
              <div class="iareply-container-disclaimer-first-start">
                {{languageChosen=='es'?"¡Aviso! esta información es generada con IA y es meramente informativa":"Disclaimer, this info is IA-generated and is merely informative, don't take it as a fact!"}}
              </div>
            </div>
          </div>
        </div>
        <div class="iareply-container-first-start">
          <button @click="envioPregunta" class="iareply-container-button">{{languageChosen=='es'?'Pregunta a LaIA':'Ask LaIA'}}</button>
        </div>
      </div>
    </div>

  <footer class="footer-wrapper">
    <span class="powered-by">Powered by GPT</span>
    <div class="social-bar">
      <a href="https://www.instagram.com/analizitis" target="_blank" class="social-link insta-link">
          <img src="@/assets/images/insta.png" alt="Instagram Logo">
      </a>
      <a href="https://x.com/analizitisai" target="_blank" class="social-link x-link">
          <img src="@/assets/images/x.png" alt="X Logo">
      </a>
    </div>
    <ul>
      <li>Tendencias:</li>
      <li><a class="link" href="#logo-and-brand">Smartphones</a></li>
      <li>·</li>
      <li><a class="link" href="#logo-and-brand">Mejores PC gaming</a></li>
      <li>·</li>
      <li><a class="link" href="#logo-and-brand">Mejores smartphones calidad precio</a></li>
      <li>·</li>
      <li><a class="link" href="#logo-and-brand">Ordenadores para diseño</a></li>
      <li>·</li>
      <li><a class="link" href="#logo-and-brand">Otras tendencias</a></li>
    </ul>
    <div class="footer-links">
      <a class="link" @click="navigateToPrivacyPolicy">Política de privacidad</a>
      <a class="link" @click="navigateToCookiePolicy">Política de cookies</a>
      <a class="link" @click="navigateTolegalWarning">Aviso legal</a>
    </div>    
  </footer>
  </appLayout>
</template>

<script>
import '@/assets/css/landingPage.css';
import appLayout from '@/components/shared/appLayout.vue';
import mainSpinner from '@/components/shared/spinner/mainSpinner.vue';
import cookieBanner from '@/components/shared/partials/cookieBanner.vue';
import {APP_API_URL, APP_ENVIRONMENT, API_ENDPOINT} from '@/env';

export default {
  name: 'landingPage',
  components: {
    appLayout,
    cookieBanner,
    mainSpinner
  },
  data() {
    return {
      mainImages: [
        'main.jpg'
      ],
      backgroundImage: '',

      languageChosen: 'es',
      unformattedCategories: [],
      categories: [],
      selectedCategory: false,
      categoryName: false,

      unformattedBudgets: [],
      budgets: [],
      unformattedPc: [],
      pcs: [],
      unformattedTablets: [],
      tablets: [],
      unformattedLaptops: [],
      laptops: [],
      unformattedSmartwatches: [],
      smartwatches: [],
      unformattedMotorbikeModel: [],
      motorbikeModels: [],
      unformattedCarModel: [],
      carModels: [],
      unformattedHobbies: [],
      hobbies: [],
      unformattedSmartphones: [],
      smartphones: [],
      unformattedMonitors: [],
      monitors: [],
      selectedConditional: false,
      selectedBudget: false,

      selectedFirstElement: undefined,
      selectedSecondElement: undefined,
      selectedThirdElement: undefined,

      iaReply: false,
      formattedIAReply: '',

      startIndexCategory: 0,
      startIndexSecond: 0,
      startIndexThird: 0,
      
      isPc: false,
      isLaptop: false,
      isTablet: false,
      isSmartwatch: false,
      isSmartphone: false,
      isMonitor: false,
      isTv: false,
      isCar: false,
      isMoto: false,
      secondLength: 0,
      loading: false
    }
  },
  beforeMount() {
    this.getAllCategories(this.languageChosen);
    this.getAllBudgets(this.languageChosen);
    this.entryLog();
    this.setRandomBackground();
  },
  mounted() {
    
    this.getAllPcs(this.languageChosen);
    this.getAllLaptops(this.languageChosen);
    this.getAllTablets(this.languageChosen);
    this.getAllSmartwatches(this.languageChosen);
    this.getAllHobbies(this.languageChosen);
    this.getAllCarTypes(this.languageChosen);
    this.getAllMotoTypes(this.languageChosen);
    this.getAllMonitorFeatures(this.languageChosen);
    this.getAllSmartphoneFeatures(this.languageChosen);
  },
  watch: {
    unformattedCategories() {
      this.categories = this.unformattedCategories;
    },
    unformattedPc() {
      this.pcs = this.unformattedPc;
    },
    unformattedTablets() {
      this.tablets = this.unformattedTablets;
    },
    unformattedLaptops() {
      this.laptops = this.unformattedLaptops;
    },
    unformattedSmartwatches() {
      this.smartwatches = this.unformattedSmartwatches;
    },
    unformattedCarModel() {
      this.carModels = this.unformattedCarModel;
    },
    unformattedMotorbikeModel() {
      this.motorbikeModels = this.unformattedMotorbikeModel;
    },
    unformattedHobbies() {
      this.hobbies = this.unformattedHobbies;
    },
    unformattedMonitors() {
      this.monitors = this.unformattedMonitors;
    },
    unformattedSmartphones() {
      this.smartphones = this.unformattedSmartphones;
    },
    unformattedBudgets() {
      this.budgets = this.unformattedBudgets;
    },
    iaReply() {
      if (this.iaReply != false){
        if (this.iaReply.indexOf('- ') > -1) {
          this.formattedIAReply = this.formatToMarkdown(this.iaReply);
          this.iaReply = false;
        } 
        else {
          this.formattedIAReply = this.iaReply;
          this.iaReply = false;
        }
      }
    },
  },
  methods: {
    changeLanguage() {
      this.languageChosen = this.languageChosen=='es'?'en':'es';
      this.getAllCategories(this.languageChosen);
      this.getAllPcs(this.languageChosen);
      this.getAllLaptops(this.languageChosen);
      this.getAllTablets(this.languageChosen);
      this.getAllSmartwatches(this.languageChosen);
      this.getAllHobbies(this.languageChosen);
      this.getAllCarTypes(this.languageChosen);
      this.getAllMotoTypes(this.languageChosen);
      this.getAllBudgets(this.languageChosen);
      this.getAllMonitorFeatures(this.languageChosen);
      this.getAllSmartphoneFeatures(this.languageChosen);
      this.selectedFirstElement != undefined? this.selectedFirstElement.classList.remove('border-image-touched'): '';
      this.selectedSecondElement != undefined? this.selectedSecondElement.classList.remove('border-image-touched'): '';
      this.selectedFirstElement = undefined;
      this.selectedSecondElement = undefined;
      this.selectedCategory = false;
      this.selectedConditional = false;
      this.formattedIAReply = false;
      this.isPc = false;
      this.isLaptop = false;
      this.isTablet = false;
      this.isSmartwatch = false;
      this.isSmartphone = false;
      this.isMonitor = false;
      this.isTv = false;
      this.isCar = false;
      this.isMoto = false;
    },
    prevClickCategory() {
      if (this.startIndexCategory > 0) {
        this.startIndexCategory--;
      }
    },
    nextClickCategory() {
      if (this.startIndexCategory < (this.categories.length -1)) {
        this.startIndexCategory++;
      }
    },
    prevClickSecond() {
      if (this.startIndexSecond > 0) {
        this.startIndexSecond--;
      }
    },
    nextClickSecond() {
      if (this.startIndexSecond < (this.secondLength -1)) {
        this.startIndexSecond++;
      }
    },
    prevClickThird() {
      if (this.startIndexThird > 0) {
        this.startIndexThird--;
      }
    },
    nextClickThird() {
      if (this.startIndexThird < (this.budgets.length -1)) {
        this.startIndexThird++;
      }
    },
    clickCheckboxLanguage() {
      this.$refs.languageCheckbox.click()
    },

    getRandomImage() {
      const randomIndex = Math.floor(Math.random() * this.mainImages.length); // Get random index
      return this.mainImages[randomIndex]; // Return the randomly selected image
    },
    // Function to change the current image
    setRandomBackground() {
      const randomImage = this.getRandomImage(); // Get a random image
      this.backgroundImage = {
        backgroundImage: `url(${require(`@/assets/images/landing/${randomImage}`)})`,
        backgroundSize: 'cover', // Adjust background-size if needed
        backgroundPosition: 'center', // Center the background image
        backgroundRepeat: 'no-repeat' // Ensure it doesn't repeat
      };
    },

    formatToMarkdown(inputString) {
      // Split the string by the delimiter "- "
    let entries = inputString.trim().split("- ").filter(entry => entry.trim() !== "");

    // Map over entries and format them
    let formattedHTML = entries.map((entry, index) => {
      // Trim the entry
      entry = entry.trim();

      // Check if it's the last entry
      if (index === entries.length - 1) {
        // Check for "example" or "ejemplo"
        let parts = entry.split(":");
        if (parts.length > 1) {
          if (parts.length > 1) {
            let query = parts[1].trim();
            // Create a Google search link
            entry = `<a href="https://www.google.com/search?q=${encodeURIComponent(query)}" target="_blank">${query}</a>`;
          }
        }
        else {
          entry = `<a href="https://www.google.com/search?q=${encodeURIComponent(entry)}" target="_blank">${entry}</a>`;
        }
      }

        // Return the formatted list item
        return `<li>${entry}</li>`;
      }).join('');

      // Wrap the list items in an unordered list
      return `<ul>${formattedHTML}</ul>`;
    },
    getSelectedCategory(entry) {
      let currentTarget = entry.target;
      while (currentTarget.nodeName != 'DIV') {
        currentTarget = currentTarget.parentNode;
      }
      this.formattedIAReply = false;
      this.selectedConditional != false? this.selectedConditional = false: '';
      this.startIndexSecond = 0;
      this.selectedSecondElement != undefined? this.selectedSecondElement.classList.remove('border-image-touched'): '';
      this.selectedSecondElement = undefined;
      this.selectedFirstElement != undefined? this.selectedFirstElement.classList.remove('border-image-touched'): '';
      this.selectedFirstElement = currentTarget.childNodes[0];
      this.selectedFirstElement.classList.add('border-image-touched');
      this.selectedCategory = currentTarget.id;
      this.categoryName = currentTarget.childNodes[1].innerText.toLowerCase();
      if (this.categoryName != undefined && (this.categoryName == 'laptop'||this.categoryName == 'portátil')) {
        this.isPc = false;
        this.isLaptop = true;
        this.isTablet = false;
        this.isSmartwatch = false;
        this.isSmartphone = false;
        this.isMonitor = false;
        this.isTv = false;
        this.isCar = false;
        this.isMoto = false;
        this.secondLength = this.laptops.length;
      }
      else if(this.categoryName != undefined && this.categoryName == 'pc') {
        this.isPc = true;
        this.isLaptop = false;
        this.isTablet = false;
        this.isSmartwatch = false;
        this.isSmartphone = false;
        this.isTv = false;
        this.isMonitor = false;
        this.isCar = false;
        this.isMoto = false;
        this.secondLength = this.pcs.length;
      }
      else if(this.categoryName != undefined && this.categoryName == 'tablet') {
        this.isPc = false;
        this.isLaptop = false;
        this.isTablet = true;
        this.isSmartwatch = false;
        this.isSmartphone = false;
        this.isTv = false;
        this.isMonitor = false;
        this.isCar = false;
        this.isMoto = false;
        this.secondLength = this.tablets.length;
      }
      else if(this.categoryName != undefined && this.categoryName == 'smartwatch') {
        this.isPc = false;
        this.isLaptop = false;
        this.isTablet = false;
        this.isSmartwatch = true;
        this.isSmartphone = false;
        this.isTv = false;
        this.isMonitor = false;
        this.isCar = false;
        this.isMoto = false;
        this.secondLength = this.smartwatches.length;
      }
      else if(this.categoryName != undefined && this.categoryName == 'smartphone') {
        this.isPc = false;
        this.isLaptop = false;
        this.isTablet = false;
        this.isSmartwatch = false;
        this.isSmartphone = true;
        this.isTv = false;
        this.isMonitor = false;
        this.isCar = false;
        this.isMoto = false;
        this.secondLength = this.smartphones.length;
      }
      else if(this.categoryName != undefined && this.categoryName == 'monitor') {
        this.isPc = false;
        this.isLaptop = false;
        this.isTablet = false;
        this.isSmartwatch = false;
        this.isSmartphone = false;
        this.isMonitor = true;
        this.isTv = false;
        this.isCar = false;
        this.isMoto = false;
        this.secondLength = this.monitors.length;
      }
      else if(this.categoryName != undefined && this.categoryName == 'tv') {
        this.isPc = false;
        this.isLaptop = false;
        this.isTablet = false;
        this.isSmartwatch = false;
        this.isTv = true;
        this.isSmartphone = false;
        this.isMonitor = false;
        this.isCar = false;
        this.isMoto = false;
        this.secondLength = this.hobbies.length;
      }
      else if(this.categoryName != undefined && (this.categoryName == 'coche'|| this.categoryName == 'car')) {
        this.isPc = false;
        this.isLaptop = false;
        this.isTablet = false;
        this.isSmartwatch = false;
        this.isSmartphone = false;
        this.isMonitor = false;
        this.isTv = false;
        this.isCar = true;
        this.isMoto = false;
        this.secondLength = this.carModels.length;
      }
      else if(this.categoryName != undefined && (this.categoryName == 'moto'|| this.categoryName == 'motorbike')) {
        this.isPc = false;
        this.isLaptop = false;
        this.isTablet = false;
        this.isSmartwatch = false;
        this.isSmartphone = false;
        this.isMonitor = false;
        this.isTv = false;
        this.isCar = false;
        this.isMoto = true;
        this.secondLength = this.motorbikeModels.length;
      }
    },
    getSelectedSecondRow(entry) {
      let currentTarget = entry.target;
      while (currentTarget.nodeName != 'DIV') {
        currentTarget = currentTarget.parentNode;
      }
      this.selectedThirdElement != undefined? this.selectedThirdElement.classList.remove('border-image-touched'): '';
      this.selectedThirdElement = undefined;
      this.selectedSecondElement != undefined? this.selectedSecondElement.classList.remove('border-image-touched'): '';
      this.selectedSecondElement = currentTarget.childNodes[0];
      this.selectedSecondElement.classList.add('border-image-touched');
      this.selectedConditional = currentTarget.id;
    },
    getSelectedThirdRow(entry) {
      let currentTarget = entry.target;
      while (currentTarget.nodeName != 'DIV') {
        currentTarget = currentTarget.parentNode;
      }
      this.selectedThirdElement != undefined? this.selectedThirdElement.classList.remove('border-image-touched'): '';
      this.selectedThirdElement = currentTarget.childNodes[0];
      this.selectedThirdElement.classList.add('border-image-touched');
      this.selectedBudget = currentTarget.id;
    },
    getImagePath(folder, image) {
      if (image != undefined){
        return require(`@/assets/images/${folder}/${image}`);
      }
    },
    async envioPregunta() {
      if (this.selectedCategory && this.selectedConditional && this.selectedBudget) {
        this.formattedIAReply = false;
        this.loading = true;
        let composedBody = {
          'category': this.selectedCategory,
          'variable': this.selectedConditional,
          'budget': this.selectedBudget,
        }
        this.isPc?composedBody.type='pc':'';
        this.isLaptop?composedBody.type='laptop':'';
        this.isTablet?composedBody.type='tablet':'';
        this.isSmartwatch?composedBody.type='smartwatch':'';
        this.isSmartphone?composedBody.type='smartphone':'';
        this.isMonitor?composedBody.type='monitor':'';
        this.isTv?composedBody.type='tv':'';
        this.isCar?composedBody.type='car':'';
        this.isMoto?composedBody.type='moto':'';
        let categoryType = composedBody.type;
        const requestData = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(composedBody)
        };
        await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['askIa'], requestData)
          .then(response => response.json())
              .then(data => {
                  if (data!=false&&data!=undefined&&data!='KO'){
                      this.loading = false;
                      this.iaReply = data;
                      this.goalLog(categoryType);
                  }
              })
            .catch(() => {
              this.loading = false;
              console.log('KO');
            })
      }
    },
    async getAllCategories() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllCategories']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedCategories = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllSmartwatches() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllSmartwatches']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedSmartwatches = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllPcs() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllPcs']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedPc = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllLaptops() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllLaptops']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedLaptops = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllTablets() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllTablets']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedTablets = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllBudgets() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllBudgets']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedBudgets = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllCarTypes() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllCarTypes']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedCarModel = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllMotoTypes() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllMotoTypes']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedMotorbikeModel = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllHobbies() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllHobbies']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedHobbies = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllMonitorFeatures() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllMonitorFeatures']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedMonitors = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async getAllSmartphoneFeatures() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      const queryParams = new URLSearchParams({ language: this.languageChosen });
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['getAllSmartphoneFeatures']+'?'+queryParams, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    this.unformattedSmartphones = data;
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    navigateToPrivacyPolicy() {
      this.$router.push('/privacy-policy');
    },
    navigateToCookiePolicy() {
      this.$router.push('/cookie-policy');
    },
    navigateTolegalWarning() {
      this.$router.push('/aviso-legal');
    },
    async entryLog() {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['entryLog'], requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    ''
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
    async goalLog(categoryType) {
      const requestData = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      };
      await fetch(APP_API_URL[APP_ENVIRONMENT]+API_ENDPOINT['goalLog']+'?category='+categoryType, requestData)
        .then(response => response.json())
            .then(data => {
                if (data!=false&&data!=undefined&&data!='KO'){
                    ''
                }
            })
        .catch(() => {
            console.log('KO');
        })
    },
  }
}
</script>