export const APP_API_URL = {
    'development': 'https://atenea.analizitis.es/',
    'production': 'https://analizitis.es/'
};

export const API_ENDPOINT = {
    'getAllCategories': 'analizitis/category/getCategoriesByOrder',
    'getAllPcs': 'analizitis/pc/getPcFeaturesByOrder',
    'getAllLaptops': 'analizitis/laptop/getLaptopFeaturesByOrder',
    'getAllTablets': 'analizitis/tablet/getTabletFeaturesByOrder',
    'getAllSmartwatches': 'analizitis/smartwatch/getSmartwatchFeaturesByOrder',
    'getAllHobbies': 'analizitis/hobby/getHobbiesByOrder',
    'getAllCarTypes': 'analizitis/carModel/getModelsByOrder',
    'getAllMotoTypes': 'analizitis/motorbikeModel/getModelsByOrder',
    'getAllMonitorFeatures': 'analizitis/monitor/getMonitorFeaturesByOrder',
    'getAllSmartphoneFeatures': 'analizitis/smartphone/getSmartphoneFeaturesByOrder',
    'getAllBudgets': 'analizitis/budget/getBudgetsByOrder',
    'askIa': 'analizitis/peticion/create',
    'entryLog': 'analizitis/log/entry',
    'goalLog': 'analizitis/log/goal',
};

export const APP_ENVIRONMENT = 'production';