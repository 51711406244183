<!-- src/components/BottomBanner.vue -->
<template>
    <div v-if="showBanner" class="bottom-banner">
        <p>Esta web no recopila información personal, solo información de rendimiento sobre la aplicación.</p>
    </div>
  </template>
  
  <script>
  import '@/assets/css/shared/partials/cookieBanner.css';

  export default {
    name: "cookieBanner",
    data() {
      return {
        showBanner: true,
      };
    },
    mounted() {
      // Hide the banner after 5 to 10 seconds
      setTimeout(() => {
        this.showBanner = false;
      }, 7000); // 7000 milliseconds = 7 seconds (you can adjust this value)
    },
  };
  </script>