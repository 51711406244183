<template>
  <div class="overlay">
    <img src="@/assets/images/analizitis.png" alt="Loading" class="spinner-image">
    <p id="spinnerCharge">Generating...</p>
  </div>
</template>
  
<script>
import '@/assets/css/shared/spinner/mainSpinner.css';

export default {
  name: 'mainSpinner',
};
</script>