<template>
    <appLayout>
      <section class="logo-and-brand-legal" id="logo-and-brand">
        <div class="logo-and-brand-legal-first">
          <div class="logo-and-brand-legal-first-start">
            <div class="logo-and-brand-legal-first-start-first">
              <img @click="backToMain" class="analizitis-logo" src="@/assets/images/analizitis.png" alt="Analizitis logo"> 
              <span @click="backToMain" class="brand">Analizitis</span>
            </div>
          </div>
        </div>
      </section>
      <div class="content-legal">
        <div class="content-legal-start">
          <div class="content-legal-start-first">
            <h1>Aviso Legal</h1>
          </div>
          <div class="content-legal-start-second">
            <p>En cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSI), se informa que la titularidad de la presente aplicación, Analizitis ("la Aplicación"), corresponde a:</p>
            
            <p class="legal-title">1. Identificación del Titular</p>
            
            <p>Analizitis S.L.U</p>
            <p>NIF: B19766690</p>
            <p>Contacto: analizitisai@gmail.com</p>
            <p>Fecha de Vigencia: desde julio de 2024</p>
          
            <p class="legal-title">2. Usuarios</p>
            <p>El acceso y/o uso de la Aplicación atribuye la condición de usuario, que acepta, desde dicho acceso y/o uso, las condiciones reflejadas en este Aviso Legal.</p>
            
            <p class="legal-title">3. Uso de la Aplicación</p>
            <p>El usuario asume la responsabilidad del uso de la Aplicación. Esta responsabilidad se extiende al registro que fuese necesario para acceder a ciertos servicios o contenidos.</p>
            
            <p class="legal-title">4. Propiedad Intelectual e Industrial</p>
            <p>Todos los derechos de propiedad intelectual e industrial de la Aplicación es titularidad de Analazitis S.L.U o, en su caso, de sus licenciantes.</p>
            
            <p class="legal-title">5. Exclusión de Garantías y Responsabilidad</p>
            <p>Analizitis no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad de la Aplicación.</p>
            
            <p class="legal-title">6. Modificaciones</p>
            <p>Analizitis S.L.U se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en la Aplicación, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que estos aparezcan presentados o localizados en la Aplicación.</p>
            
            <p class="legal-title">7. Enlaces</p>
            <p>En el caso de que en la Aplicación se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, Analizitis S.L.U no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso Analizitis S.L.U asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno.</p>
            
            <p class="legal-title">8. Derecho de Exclusión</p>
            <p>Analizitis S.L.U se reserva el derecho a denegar o retirar el acceso a la Aplicación y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.</p>
            
            <p class="legal-title">9. Legislación Aplicable y Jurisdicción</p>
            <p>La relación entre Analizitis S.L.U y el usuario se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y Tribunales de Madrid.</p>

            <p class="legal-title">10. Contacto</p>
            <p>Para cualquier consulta o comentario acerca del presente Aviso Legal, puede ponerse en contacto con nosotros a través del siguiente correo electrónico: analizitisai@gmail.com</p>
          </div>
        </div>
      </div>
    </appLayout>
</template>
  
  <script>
  import appLayout from '@/components/shared/appLayout.vue';
  import '@/assets/css/avisoLegal.css';


  export default {
    name: 'avisoLegal',
    components: {
        appLayout
    },
    methods: {
      backToMain() {
        this.$router.push('/');
      }
    }
  };
  </script>