<template>
    <appLayout>
      <section class="logo-and-brand-policy" id="logo-and-brand">
        <div class="logo-and-brand-policy-first">
          <div class="logo-and-brand-policy-first-start">
            <div class="logo-and-brand-policy-first-start-first">
              <img @click="backToMain" class="analizitis-logo" src="@/assets/images/analizitis.png" alt="Analizitis logo"> 
              <span @click="backToMain" class="brand">Analizitis</span>
            </div>
          </div>
        </div>
      </section>
      <div class="content-privacy">
        <div class="content-privacy-start">
          <div class="content-privacy-start-first">
            <h1>Política de privacidad</h1>
          </div>
          <div class="content-privacy-start-second">
            <p>Fecha de Vigencia: desde julio de 2024</p>
            <p class="policy-title">1. Introducción</p>
            <p>Bienvenido a Analizitis. Esta Política de Privacidad explica cómo recopilamos, usamos y protegemos la información de los usuarios de nuestra Aplicación. Al utilizar la Aplicación, usted acepta las prácticas descritas en esta política.</p>
            <p class="policy-title">2. Información Recopilada</p>
            <p>Nuestra Aplicación no recopila, almacena ni procesa datos personales de los usuarios. La única información que recopilamos son las interacciones que los usuarios tienen con la Aplicación.</p>
            <p class="policy-title">3. Uso de la Información</p>
            <p>Las interacciones con la Aplicación se utilizan exclusivamente para mejorar la funcionalidad y la experiencia del usuario dentro de la Aplicación. No compartimos, vendemos ni revelamos esta información a terceros.</p>
            <p class="policy-title">4. Retención y Eliminación de la Información</p>
            <p>Las interacciones recopiladas se almacenan por un período máximo de un mes. Después de este período, toda la información recopilada se elimina de manera permanente de nuestros sistemas.</p>
            <p class="policy-title">5. Uso de la API de OpenAI</p>
            <p>La Aplicación utiliza la API de OpenAI para proporcionar sus funcionalidades. OpenAI puede procesar datos como parte de su servicio, sujeto a sus términos y políticas de privacidad. No almacenamos ni retenemos los datos procesados por OpenAI.</p>
            <p class="policy-title">6. Seguridad de la Información</p>
            <p>Implementamos medidas de seguridad razonables para proteger la información de los usuarios contra accesos no autorizados, alteraciones, divulgaciones o destrucciones. Sin embargo, ningún sistema de seguridad es infalible, y no podemos garantizar la seguridad absoluta de la información.</p>
            <p class="policy-title">7. Cambios en esta Política de Privacidad</p>
            <p>Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será efectivo inmediatamente después de la publicación de la política revisada en la Aplicación. Recomendamos a los usuarios revisar periódicamente esta política para mantenerse informados sobre cómo protegemos su información.</p>
            <p class="policy-title">8. Aceptación de la Política de Privacidad</p>
            <p>Al utilizar nuestra Aplicación, usted acepta esta Política de Privacidad. Si no está de acuerdo con esta política, por favor, no utilice nuestra Aplicación.</p>
            <p class="policy-title">9. Legislación Aplicable</p>
            <p>Esta Política de Privacidad se regirá e interpretará de acuerdo con las leyes aplicables </p>
          </div>
        </div>
      </div>
    </appLayout>
</template>
  
  <script>
  import appLayout from '@/components/shared/appLayout.vue';
  import '@/assets/css/privacyPolicy.css';


  export default {
    name: 'privacyPolicy',
    components: {
        appLayout
    },
    methods: {
      backToMain() {
        this.$router.push('/');
      }
    }
  };
  </script>